<template>
  <AModalContent
    class="harvest-modal"
    :class="{ 'harvest-modal-harvesting': !isHarvested }"
    :show-close="isHarvested"
  >
    <transition
      name="t-harvest-modal"
      mode="out-in"
    >
      <div
        v-if="isHarvested"
        key="harvested"
        class="harvest-modal-step"
      >
        <div class="harvest-modal-icon icon success" />
        <div class="harvest-modal-title">
          Meth Successfully harvested 
        </div>
        <!--<div class="harvest-modal-banner">
          <p class="harvest-modal-tooltip font-bold text-xl">
            Loot Box Ecosytem is Live! Open, Unlock & Explore now!
          </p>
          <a
            class="harvest-modal-add-to-calendar-link"
            href="/lootbox"
            target="_blank"
            rel="noopener noreferrer"
          >Loot Box</a>
          <img
            src="@/assets/img/Bulls/ape-marketing.webp"
            class="harvest-modal-img"
            alt="ape"
          >
        </div>-->
        <!-- <StartPageWeeklyWinner /> -->
        <div
          v-if="showEmailForm"
          class="harvest-modal-form"
        >
          <form
            v-if="!isSubmited"
            action="#"
            class="seva-form formkit-form"
            method="post"
            data-sv-form="3806602"
            data-uid="c447e2fec7"
            data-format="inline"
            data-version="5"
            @submit.prevent="submit"
          >
            <div data-style="clean">
              <ul
                class="formkit-alert formkit-alert-error"
                data-element="errors"
                data-group="alert"
              />
              <div
                data-element="fields"
                data-stacked="true"
                class="seva-fields formkit-fields harvest-modal-form-fields"
              >
                <div class="formkit-field harvest-modal-form-input">
                  <input
                    v-model="email"
                    type="text"
                    class="formkit-input app-input"
                    name="email_address"
                    aria-label="Email Address"
                    placeholder="Email Address"
                    required
                  >
                  <div
                    v-if="emailError"
                    class="harvest-modal-form-error"
                  >
                    {{ emailError }}
                  </div>
                </div>

                <button
                  data-element="submit"
                  class="formkit-submit formkit-submit harvest-modal-form-button"
                  :class="{ disabled: isSending }"
                >
                  <div class="formkit-spinner">
                    <div />
                    <div />
                    <div />
                  </div>
                  <span>
                    <!--Yes, I'm In!-->
                    Notify me!
                  </span>
                </button>
              </div>
            </div>
          </form>
          <div
            v-else
            class="harvest-modal-form-success"
          >
            Success! Now check your email to confirm subscription.
          </div>
        </div>

        <!-- <div class="harvest-modal-add-to-calendar">
          <p class="harvest-modal-add-to-calendar-title">
            Ape Art Reveal Feb 28th 9am CST
          </p>

          <a
            class="harvest-modal-add-to-calendar-link"
            href="https://twitter.com/BullsApesProj/status/1623699177082421248?s=20&t=B2uPuyeChRHWlX7wyCpkwA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Set reminder
          </a>
        </div>-->
        <div
          v-if="!isSubmited && showEmailForm"
          class="harvest-modal-tooltip-close"
          @click="hide"
        >
          No, thanks
        </div>
      </div>
      <div
        v-else
        class="harvest-modal-step"
      >
        <div class="harvest-modal-icon">
          <img
            class="loader"
            src="@/assets/img/spinner-clear.svg"
            width="64"
          >
        </div>
        <div class="harvest-modal-title">
          Harvesting...
        </div>
        <div
          v-if="harvestTotal"
          class="harvest-modal-progress"
        >
          <div
            class="harvest-modal-progress-active"
            :style="{ width: progress + '%' }"
          />
        </div>
        <div
          v-if="harvestTotal"
          class="harvest-modal-counter"
        >
          <b>{{ harvestedAssets }} / {{ harvestTotal }}</b> Harvested
        </div>
        <div
          v-if="!harvestTotal"
          class="harvest-modal-harvesting-alert"
        >
          0 out of all assets harvested, PLEASE WAIT
        </div>
        <div class="harvest-modal-harvesting-description">
          Please wait
        </div>
      </div>
    </transition>
  </AModalContent>
</template>
<script>
import IClose from "@/assets/icons/close.svg?inline";
import IForm from "@/assets/icons/form.svg?inline";
import IAlarm from "@/assets/icons/alarm.svg?inline";
import axios from "axios";
import AModalContent from "@/components/Modal/AModalContent.vue";
// import StartPageWeeklyWinner from "../StartPage/StartPageWeeklyWinner.vue";
import { useWeb3Store } from "@/store/web3";
import { useStorage } from "@vueuse/core";
export default {
  components: {
    IClose,
    IForm,
    IAlarm,
    AModalContent,
    // StartPageWeeklyWinner,
  },
  props: {
    isHarvested: Boolean,
    harvestTotal: {
      type: Number,
      default: 0,
    },
    harvestPending: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    email: "",
    isSubmited: false,
    isSending: false,
    emailError: "",
  }),

  computed: {
    showEmailForm() {
      return !this.sentEmailBefore && this.allowEmailForm
    },
    allowEmailForm() {
      return useStorage('BAP-SHOW-HARVEST-EMAIL', true, localStorage).value;
    },
    sentEmailBefore() {
      return useWeb3Store().emailSubmited;
    },
    progress() {
      return Math.round((this.harvestedAssets / this.harvestTotal) * 100);
    },
    harvestedAssets() {
      return this.harvestTotal - this.harvestPending;
    },
  },
  methods: {
    hide() {
      useStorage('BAP-SHOW-HARVEST-EMAIL', true, localStorage).value = false
      this.$emit("close");
    },

    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async submit() {
      this.emailError = "";
      if (!this.validateEmail(this.email)) {
        this.emailError = "Please enter correct email";
        return;
      }
      this.isSending = true;
      try {
        await axios({
          url: "https://api.convertkit.com/v3/forms/3846527/subscribe",
          method: "POST",
          data: {
            api_key: "oFAO94oBcay5ACS5DbY5Kg",
            email: this.email,
            fields: {
              collectionwallet: useWeb3Store().collectionWallet,
            },
          },
        });
        this.isSubmited = true;
      } catch (err) {
        this.emailError = "Something went wrong. Please try again";
        console.log("error", err);
      }
      this.isSending = false;
    },
  },
};
</script>
